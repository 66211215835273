/* globals ga, gtag */

import type { NProgressOptions } from 'nprogress';
import { v4 as uuidv4 } from 'uuid';

import * as QueryStringHelper from '../../../Widgets/js/common/utils/QueryStringHelper';
import * as ReferrerHelper from '../../../Widgets/js/common/utils/ReferrerHelper';
import type { CoverageMapType } from '../../../Widgets/js/CoverageMap';
import type { Ad } from '../@types/Ad';
import type { Address } from '../@types/Address';
import type { AddressMaxMind } from '../@types/AddressMaxMind';
import type { AnalyticsClientSideData } from '../@types/AnalyticsClientSideData';
import type { SearchCriteriaCommon } from '../@types/SearchCriteriaCommon';
import type { SiteConfiguration } from '../@types/SiteConfiguration';
import type { Staff } from '../@types/Staff';
import type { TypeaheadItem } from '../@types/TypeaheadItem';
import type {
    GetLocationError,
    GetLocationResult,
    NoUiDoubleSliderConfig,
    NoUiSliderConfig,
    NoUiSliderConfigPeg,
    RemarketingData
} from '../@types/WhistleOutContext';
import type { WoDataLayerItem } from '../@types/WoDataLayer';
import { EmailMySearch } from '../EmailMySearch';
import { getState } from '../redux/store';
import { initDataLayer, pushDataLayerItem } from '../utils/datalayer';
import * as queryStringUtils from '../utils/queryString';
import { tryWaitFor } from '../utils/timer';
import * as deferred from '../vendor/deferred';
import { WhistleOutCore } from './core';
import { rollbar } from './rollbar';

window['wo$'] = window['wo$'] || jQuery.noConflict();
window.jQuery = window.jQuery || window['wo$'];
window.Cookies = window.Cookies || Cookies;

const scrollDefaultDuration = 500;
const scrollDefaultOffset = -100;

type selectStoreHandler = (selectedStoreId: string, selectedStaff: unknown) => void;

export class WhistleOutContext {
    scope: 'shared' | 'widget' = 'shared';
    isInitRequested: boolean;
    Core = new WhistleOutCore();

    notifications = wo$.Callbacks('unique');
    retailSplashTimer: number;
    Rollbar: typeof rollbar.instance;
    turnstileId: string;

    // TODO: Comine with getSiteConfiguration()
    config: {
        rollbar: typeof rollbar.config;
        analytics: AnalyticsClientSideData;
    };
    siteConfiguration: SiteConfiguration;
    byoPhonePickerController: {
        show(
            selectedHandler: (selectedModel: { searchUrl: string }, genericSearchUrl: string) => void,
            brandShortUrl?: string,
            phoneShortUrl?: string
        ): void;
    };

    initWidget: () => void;
    CoverageMap: CoverageMapType;

    getQueryStringParameter: (name: string, url?: string) => string = QueryStringHelper.getQueryStringParameter;
    appendQueryParam: (queryString: string, param: string) => string = QueryStringHelper.appendQueryParam;
    updateQueryStringParameter: (url: string, key: string, value: string) => string =
        QueryStringHelper.updateQueryStringParameter;

    async initializeRollbar() {
        if (this.Rollbar) {
            return;
        }

        this.config.rollbar = Object.assign(rollbar.config, this.config.rollbar);
        const siteConfig = this.getSiteConfiguration();
        if (siteConfig) {
            if (siteConfig.rollbarKey) rollbar.config.token = siteConfig.rollbarKey;
            if (siteConfig.rollbarEnvironment) rollbar.config.env = siteConfig.rollbarEnvironment;
            if (siteConfig.anonymiseTracking) rollbar.config.captureIp = siteConfig.anonymiseTracking !== true;
        }

        await rollbar.init();
        this.Rollbar = rollbar.instance;
    }

    readLookupData<T extends string | number | boolean | symbol | object>(
        parent: JQuery<HTMLElement>,
        force?: boolean
    ): T {
        if (!parent) throw Error('parent needs to be provided');

        if (force === true) {
            // Use attr instead to prevent jquery from caching client side data as it does not get updated when DOM is manipulated
            return JSON.parse(parent.find('div[data-client-side-data]').attr('data-client-side-data'));
        }

        return parent.find('div[data-client-side-data]').data('clientSideData') as T;
    }

    setLookupData(parent: JQuery<HTMLElement>, data: string | number | boolean | symbol | object) {
        if (!parent) throw Error('parent needs to be provided');
        return parent.find('div[data-client-side-data]').data('clientSideData', data);
    }

    getCurrentLocation(
        successCallback: (address: GetLocationResult) => void,
        failCallback?: (error?: GetLocationError) => void
    ) {
        this.getCurrentLocationViaHtml5(successCallback, () => {
            this.getCurrentLocationViaApi(successCallback, failCallback);
        });
    }

    getCurrentLatLng(
        successCallback: (address: GetLocationResult) => void,
        failCallback?: (error?: GetLocationError) => void
    ) {
        this.getCurrentLatLngViaHtml5(successCallback, () => {
            this.getCurrentLatLngViaApi(successCallback, failCallback);
        });
    }

    getCurrentLatLngViaHtml5(
        successCallback: (address: GetLocationResult) => void,
        failCallback: (error?: GetLocationError) => void
    ) {
        if (
            'geolocation' in navigator &&
            (this.getSiteConfiguration() || <SiteConfiguration>{}).enableHtml5Geolocation
        ) {
            navigator.geolocation.getCurrentPosition(function (position) {
                successCallback({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            }, failCallback as unknown as PositionErrorCallback);
        } else {
            if (failCallback) failCallback();
        }
    }

    getCurrentLocationViaHtml5(
        successCallback: (address: GetLocationResult) => void,
        failCallback?: (error?: GetLocationError) => void
    ) {
        this.getCurrentLatLngViaHtml5(function (latLng) {
            const lat = latLng.lat;
            const lng = latLng.lng;
            wo$.ajax({
                type: 'GET',
                url: '/Ajax/Shared/Geo/Geocode?lat=' + lat + '&lng=' + lng,
                success: function (addressResult) {
                    addressResult.street = null;
                    addressResult.streetNumber = null;
                    if (!addressResult.city || addressResult.city.length < 1) {
                        if (failCallback) failCallback();
                        return;
                    }
                    successCallback({
                        label: this.getLabel(addressResult),
                        lat: lat,
                        lng: lng,
                        countryCode: (addressResult.country || {}).shortName
                    });
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    return failCallback({
                        jqXHR: jqXHR,
                        textStatus: textStatus,
                        errorThrown: errorThrown
                    });
                }
            });
        }, failCallback);
    }

    getCurrentLatLngViaApi(
        successCallback: (address: GetLocationResult) => void,
        failCallback: (error?: GetLocationError) => void
    ) {
        const cookie = this.getLocationCookie();
        if (cookie) {
            successCallback(cookie);
            return;
        }

        wo$.ajax({
            url: '/Ajax/Shared/Geo/GetCurrentCity',
            dataType: 'json,text',
            success: response => {
                if (!response || !response.city || response.city.length < 1) {
                    if (failCallback) failCallback();
                    return;
                }

                response.postal = null;

                const res = {
                    lat: response.location.latitude,
                    lng: response.location.longitude,
                    countryCode: response.country.iso_code,
                    label: this.getLabelFromMaxMind(response)
                };
                this.setLocationCookie(res);
                successCallback(res);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                return failCallback({
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown
                });
            }
        });
    }

    getCurrentLocationViaApi(
        successCallback: (address: GetLocationResult) => void,
        failCallback: (error?: GetLocationError) => void
    ) {
        const cookie = this.getLocationCookie();
        if (cookie && cookie.label) {
            successCallback(cookie);
            return;
        }

        const config = this.getSiteConfiguration();
        if (!config || !config.autoDetectLocation) {
            if (failCallback) {
                failCallback();
                return;
            }
        }

        this.getCurrentLatLngViaApi(function (addressResult) {
            successCallback(addressResult);
        }, failCallback);
    }

    setLocationCookie(value: { lat: number; lng: number; countryCode: string; label: string }) {
        if (typeof window === 'undefined' || !window || !wo$) {
            return;
        }

        const key = 'location';
        if (!value) {
            Cookies.remove(key);
            return;
        }

        const now = new Date();
        const time = now.getTime();
        const oneDay = 1 * 24 * 60 * 60;
        const expireTime = time + oneDay;
        now.setTime(expireTime);

        Cookies.set(key, JSON.stringify(value), { path: '/', expires: now });
    }

    getLocationCookie() {
        const cookie = window.Cookies.get('location');
        return cookie ? JSON.parse(cookie) : null;
    }

    getLabel(address: Address) {
        let label: string;
        if (address.streetNumber || address.street) {
            label =
                this.getComponentValue(address.streetNumber) +
                ' ' +
                this.getComponentValue(address.street, true) +
                ' ' +
                this.getComponentValue(address.city) +
                ', ' +
                this.getComponentValue(address.state, true) +
                ' ' +
                this.getComponentValue(address.postcode);
        } else if (address.postcode || address.city) {
            label =
                this.getComponentValue(address.city) +
                ', ' +
                this.getComponentValue(address.state, true) +
                ' ' +
                this.getComponentValue(address.postcode);
        } else if (address.state) {
            label = this.getComponentValue(address.state);
        } else {
            label = this.getComponentValue(address.country);
        }
        return label.trim();
    }

    getLabelFromMaxMind(address: AddressMaxMind) {
        let label: string;
        if (address.city && (address.city.names || {}).en) {
            label = address.city.names.en;
            if (address.subdivisions && address.subdivisions.length) {
                label = label + ' ' + address.subdivisions[0].iso_code;
            }
            if (address.postal) {
                label = label + ', ' + address.postal.code;
            }
        } else if (address.subdivisions.length) {
            label = address.subdivisions[0].iso_code;
        } else {
            label = address.country.names.en;
        }
        return label.trim();
    }

    getComponentValue(
        component: {
            shortName: string;
            longName: string;
        },
        preferShorter?: boolean
    ) {
        if (!component) return '';
        if (preferShorter === true && component.shortName && component.shortName.length > 0) return component.shortName;
        return component.longName;
    }

    appendReferrer(widgetLink: JQuery) {
        const transaction = widgetLink.data('transaction');
        if (transaction === 'GoToSite') {
            const href = widgetLink.attr('href');
            const referrer = ReferrerHelper.getReferrerEncoded();
            if (referrer) {
                widgetLink.attr('href', QueryStringHelper.updateQueryStringParameter(href, 'r', referrer));
            }
        }
    }

    createSlider(
        sliderElement: JQuery<HTMLElement>,
        textElement: JQuery<HTMLElement>,
        sliderConfig: NoUiSliderConfig,
        format: wNumb.Instance,
        onChange: (peg?: NoUiSliderConfigPeg) => void,
        rebind: boolean
    ) {
        if (!sliderElement || !textElement || !sliderConfig) {
            return;
        }

        if (!rebind) {
            rebind = false;
        }

        if (!rebind) {
            sliderElement
                .noUiSlider({
                    step: 1,
                    behaviour: 'drag',
                    connect: 'lower',
                    format: format,
                    range: { min: [0], max: [sliderConfig.pegs.length - 1] },
                    start: [0]
                })
                .on({
                    slide: function () {
                        const index = sliderElement.val() as number;
                        const text = sliderConfig.pegs[index].text;
                        textElement.html(text);
                    },
                    set: function () {
                        const index = sliderElement.val() as number;
                        sliderConfig.currentPeg = sliderConfig.pegs[index];
                        const text = sliderConfig.pegs[index].text;
                        textElement.html(text);
                    },
                    change: function () {
                        const index = sliderElement.val() as number;
                        const currentPeg = sliderConfig.pegs[index];
                        sliderConfig.currentPeg = currentPeg;
                        if (onChange) {
                            onChange(currentPeg);
                        }
                    }
                });
        }

        sliderElement.val(sliderConfig.currentPeg.index);
    }

    createDoubleSlider(
        sliderElement: JQuery<HTMLElement>,
        textElement: JQuery<HTMLElement>,
        sliderConfig: NoUiDoubleSliderConfig,
        format: wNumb.Instance,
        onChange: () => void,
        textMaxElement: JQuery<HTMLElement>,
        showMaxTextForFullRange: boolean
    ) {
        sliderElement
            .noUiSlider({
                step: 1,
                behaviour: 'drag',
                format: format,
                connect: true,
                range: { min: [0], max: [sliderConfig.pegs.length - 1] },
                start: [0, sliderConfig.pegs.length - 1]
            })
            .on({
                slide: function () {
                    const index = sliderElement.val();
                    if (textMaxElement) {
                        textElement.html(sliderConfig.pegs[index[0]].text);
                        textMaxElement.html(sliderConfig.pegs[index[1]].text);
                    } else {
                        let text = sliderConfig.pegs[index[0]].text + ' - ' + sliderConfig.pegs[index[1]].text;

                        // If default ranges replace with any
                        if (
                            showMaxTextForFullRange &&
                            sliderConfig.pegs[index[0]].value === sliderConfig.pegs[0].value &&
                            sliderConfig.pegs[index[1]].value === sliderConfig.pegs[sliderConfig.pegs.length - 1].value
                        ) {
                            text = sliderConfig.pegs[index[1]].text;
                        }

                        textElement.html(text);
                    }
                },
                set: function () {
                    const index = sliderElement.val();
                    sliderConfig.currentPeg1 = sliderConfig.pegs[index[0]];
                    sliderConfig.currentPeg2 = sliderConfig.pegs[index[1]];
                    if (textMaxElement) {
                        textElement.html(sliderConfig.pegs[index[0]].text);
                        textMaxElement.html(sliderConfig.pegs[index[1]].text);
                    } else {
                        let text = sliderConfig.pegs[index[0]].text + ' - ' + sliderConfig.pegs[index[1]].text;

                        // If default ranges replace with any
                        if (
                            showMaxTextForFullRange &&
                            sliderConfig.pegs[index[0]].value === sliderConfig.pegs[0].value &&
                            sliderConfig.pegs[index[1]].value === sliderConfig.pegs[sliderConfig.pegs.length - 1].value
                        ) {
                            text = sliderConfig.pegs[index[1]].text;
                        }

                        textElement.html(text);
                    }
                },
                update: function () {
                    const index = sliderElement.val();
                    if (textMaxElement) {
                        textElement.html(sliderConfig.pegs[index[0]].text);
                        textMaxElement.html(sliderConfig.pegs[index[1]].text);
                    } else {
                        const text = sliderConfig.pegs[index[0]].text + ' - ' + sliderConfig.pegs[index[1]].text;
                        textElement.html(text);
                    }
                },
                change: function () {
                    const index = sliderElement.val();
                    sliderConfig.currentPeg1 = sliderConfig.pegs[index[0]];
                    sliderConfig.currentPeg2 = sliderConfig.pegs[index[1]];
                    if (onChange) {
                        onChange();
                    }
                }
            })
            .val([sliderConfig.currentPeg1.index, sliderConfig.currentPeg2.index]);
    }

    isiOS() {
        return navigator && navigator.platform && /iP(hone|od|ad)/.test(navigator.platform);
    }

    readonly endProgressEventName = 'endProgressEvent';

    startProgress(parent: string, trickleRate: number, trickleSpeed: number, showSpinner?: boolean) {
        const element = wo$(parent);
        if (element.length === 0) return;

        if (typeof trickleRate === 'undefined') trickleRate = 0.1;
        if (typeof trickleSpeed === 'undefined') trickleSpeed = 0.1;
        if (typeof parent === 'undefined') parent = 'document';

        window.NProgress.configure({
            parent: parent,
            trickleRate: trickleRate,
            trickleSpeed: trickleSpeed,
            showSpinner: showSpinner === undefined ? true : showSpinner
        } as Partial<NProgressOptions>);

        window.NProgress.start();
        element.block({ message: null });

        let endProgressTimer: NodeJS.Timer;
        if (this.isiOS()) {
            // HACK: In iOS, the standard events such as 'pageshow', 'popstate' or 'unload' don't work when the page is cached
            // So we need to end the progress manually after an interval
            endProgressTimer = setTimeout(() => {
                this.endProgress(parent);
            }, 5000);
        }

        const onPageShow = event => {
            this.endProgressOnPageShow(event, parent, endProgressTimer);
        };

        // We need to explicitly call endProgress() if the page is from cache
        // otherwise the indicator never stops on History.Back in Safari,
        // because Safari reads the page from the AppCache
        window.addEventListener('pageshow', onPageShow, false);

        window.addEventListener(this.endProgressEventName, () =>
            window.removeEventListener('pageshow', onpageshow, false)
        );
    }

    endProgressOnPageShow(event: PageTransitionEvent, parent: string, endProgressTimer?: NodeJS.Timer) {
        if (event.persisted) {
            if (endProgressTimer) {
                // Disable the iOS hack, because it's a normal workflow
                clearTimeout(endProgressTimer);
            }

            this.endProgress(parent);
        }

        dispatchEvent(new CustomEvent(this.endProgressEventName));
    }

    endProgress(parent: string) {
        if (typeof parent === 'undefined') parent = 'document';
        window.NProgress.done();
        wo$(parent).unblock({ message: null });
    }

    isPositiveNumber(value: string | number) {
        return wo$.isNumeric(value) && value !== '-1' && value !== -1;
    }

    isNumber(value: string | number) {
        return wo$.isNumeric(value);
    }

    replaceQueryString(url: string, newQuery: string) {
        if (url === null || url === undefined) {
            return url;
        }

        const regex = /\?(.*)$/gi;
        const baseUrl = url.replace(regex, '');
        if (newQuery === null || newQuery === undefined) {
            return baseUrl;
        }

        newQuery = newQuery.replace(/^\?/, '');
        return newQuery.length ? `${baseUrl}?${newQuery}` : baseUrl;
    }

    getAnalyticsClientSideData(): AnalyticsClientSideData {
        return this.config.analytics;
    }

    getSiteConfiguration(): SiteConfiguration {
        if (this.siteConfiguration) {
            return this.siteConfiguration;
        }

        const siteConfigurationContainer: HTMLElement =
            document.querySelector('#site-configuration-container') ?? document.querySelector('#config');

        const config: SiteConfiguration = siteConfigurationContainer
            ? this.readLookupData(wo$(siteConfigurationContainer))
            : null;

        this.siteConfiguration = config;
        return config;
    }

    isCookieEnabledByCategory(category: string) {
        const oneTrustCookieValue = Cookies.get('OptanonConsent');
        if (!oneTrustCookieValue) {
            return true;
        }

        if (oneTrustCookieValue.indexOf(`${category}:1`) >= 0) {
            return true;
        }

        return false;
    }

    isPerformanceCookieEnabled() {
        return this.isCookieEnabledByCategory('C0002');
    }

    isTargetingCookieEnabled() {
        return this.isCookieEnabledByCategory('C0004');
    }

    setYouTubeEnhancedPrivacy() {
        if (!this.isTargetingCookieEnabled()) {
            const frames = document.getElementsByTagName('iframe');
            for (let i = 0; i < frames.length; i++) {
                frames[i].src = frames[i].src.replace(/www.youtube.com/gi, 'www.youtube-nocookie.com');
            }
        }
    }

    trackGaEvent(category: string, action: string, label: string, value: object, fields: object) {
        const analyticsClientSideData = this.getAnalyticsClientSideData();
        const config = analyticsClientSideData.config;
        const data = analyticsClientSideData.data;

        wo$.each(config.accounts, (index, account) => {
            if (!account.affiliateOwned && data.includeEventTracking) {
                try {
                    if (typeof fields === 'undefined') {
                        ga(account.trackerId + '.send', 'event', category, action, label, value);
                    } else {
                        ga(account.trackerId + '.send', 'event', category, action, label, value, fields);
                    }
                } catch (err) {
                    this.Rollbar.error(
                        `WhistleOut.trackGaEvent: category=${category}, action=${action}, label=${label}`,
                        err
                    );
                }
            }
        });
    }

    trackEvent(category: string, action: string, label?: string, value?: { nonInteraction: boolean }, fields?: object) {
        try {
            if (!this.isPerformanceCookieEnabled()) {
                return;
            }

            this.trackGaEvent(category, action, label, value, fields);
            this.pushGtmEvent(category, action, label, value);
        } catch (err) {
            this.Rollbar.error(`WhistleOut.trackEvent: category=${category}, action=${action}, label=${label}`, err);
        }
    }

    trackEventV2(name: string, params?: Record<string, string | number>) {
        try {
            if (!this.isPerformanceCookieEnabled()) {
                return;
            }

            this.pushGtmEventV2(name, params);
        } catch (err) {
            this.Rollbar.error(`WhistleOut.trackEventV2: name=${name}`, err);
        }
    }

    sendPageview(
        url: string,
        analyticsClientSideData?: AnalyticsClientSideData,
        config?: AnalyticsClientSideData['config'],
        data?: AnalyticsClientSideData['data']
    ) {
        try {
            if ((!url || url.length <= 0) && analyticsClientSideData.data) url = data.trackingUrl;
            if (!url || url.length <= 0) url = location.pathname + location.search;

            wo$.each(config.accounts, function (index: number, account) {
                ga('create', account.accountNumber, { name: account.trackerId, useAmpClientId: true }, 'auto');
                if (account.linkIdTracking) {
                    ga(account.trackerId + '.require', 'linkid');
                }
                if (account.optimizeAccount) {
                    ga(account.trackerId + '.require', account.optimizeAccount);
                }
                if (url && url.length > 0) {
                    ga(account.trackerId + '.set', 'page', url);
                }
                if (data.dimensions && data.dimensions.length > 0) {
                    wo$.each(data.dimensions, function (index: number, value) {
                        ga(account.trackerId + '.set', 'dimension' + (index + 1), value);
                    });
                }
                if (config.anonymiseTracking) {
                    ga(account.trackerId + '.set', 'anonymizeIp', true);
                }
                ga(account.trackerId + '.send', 'pageview');
            });
        } catch (err) {
            this.Rollbar.error(`WhistleOut.sendPageview: url=${url}`, err);
        }
    }

    // TODO: Name of the function only differs in casing, replace all usages with the original one and remove
    sendPageView(
        url: string,
        analyticsClientSideData?: AnalyticsClientSideData,
        config?: AnalyticsClientSideData['config'],
        data?: AnalyticsClientSideData['data']
    ) {
        this.sendPageview(url, analyticsClientSideData, config, data);
    }

    addPageImpression(
        url: string,
        analyticsClientSideData: AnalyticsClientSideData,
        config: AnalyticsClientSideData['config'],
        data: AnalyticsClientSideData['data'],
        values: string[] | string,
        isAjax: boolean,
        referrer: string
    ) {
        try {
            if ((!url || url.length <= 0) && analyticsClientSideData.data) url = data.trackingUrl;
            if (!url || url.length <= 0) url = location.pathname + location.search;

            if (data.includeWhistleOutTracking) {
                if (!values || values.length <= 0) values = data.values;
                if (!values || values.length <= 0) values = '|||||||||||||||||||';
                if (isAjax !== true) isAjax = false;
                if (!referrer) referrer = document.referrer;
                const src =
                    '/track?u=' +
                    encodeURIComponent(url) +
                    '&a=' +
                    isAjax +
                    '&v=' +
                    values +
                    '&p=' +
                    data.productAreaId +
                    '&af=' +
                    config.affiliateId +
                    '&ad=' +
                    config.affiliateDomainId +
                    '&tr=' +
                    data.isTransaction +
                    '&pr=' +
                    data.isProduct +
                    '&r=' +
                    encodeURIComponent(referrer) +
                    '&m=' +
                    this.isMobileDevice();
                const image = new Image(1, 1);
                image.src = src;
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                image.onload = function () {};
            }
        } catch (err) {
            this.Rollbar.error(`WhistleOut.addPageImpression: url=${url}`, err);
        }
    }

    getWoUserId(userInfoCookieKey: string) {
        const userInfoCookieValue = Cookies.get(userInfoCookieKey);

        if (userInfoCookieValue) {
            const cookieValues = userInfoCookieValue.split('|');
            if (cookieValues && cookieValues[0]) {
                return cookieValues[0];
            }
        }

        return this.setWoUserId(userInfoCookieKey);
    }

    generateUuid() {
        // source code from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    setWoUserId(userInfoCookieKey: string) {
        let woUserId = '00000000-0000-0000-0000-000000000000';
        try {
            woUserId = uuidv4();
        } catch {
            woUserId = this.generateUuid();
        }

        Cookies.set(userInfoCookieKey, woUserId, { path: '/', expires: 60 });
        return woUserId;
    }

    trackPageView(url?: string, values?: string[] | string, isAjax?: boolean, referrer?: string) {
        try {
            const analyticsClientSideData = this.getAnalyticsClientSideData();
            const config = analyticsClientSideData.config;
            const data = analyticsClientSideData.data;

            if (config.trackPageImpression) {
                this.addPageImpression(url, analyticsClientSideData, config, data, values, isAjax, referrer);

                if (this.isPerformanceCookieEnabled()) {
                    this.sendPageview(url, analyticsClientSideData, config, data);
                    this.pushGtmPageView(config, data);
                }
            }
        } catch (err) {
            this.Rollbar.error(`WhistleOut.trackPageView: url=${url}`, err);
        }
    }

    pushDataLayerItem = pushDataLayerItem;

    pushGtmEvent(category: string, action: string, label: string, value: { nonInteraction: boolean }) {
        try {
            let customNonInteraction = false;
            if (value && value.nonInteraction) {
                customNonInteraction = true;
            }

            const analyticsClientSideData = this.getAnalyticsClientSideData();
            const data = analyticsClientSideData.data;

            this.pushDataLayerItem({
                event: `wo_${category}_${action}`.toLowerCase(),
                customCategory: category,
                customAction: action,
                customLabel: label,
                customNonInteraction: customNonInteraction,
                customValue: isNaN(value as never) ? undefined : +value,
                customCountry: data.country,
                customProductAreaId: data.productAreaId,
                customUserId: this.getWoUserId(analyticsClientSideData.config.userInfoCookieKey)
            });
        } catch (err) {
            this.Rollbar.error(`WhistleOut.pushGtmEvent: category=${category}, action=${action}, label=${label}`, err);
        }
    }

    pushGtmEventV2(name: string, params?: Record<string, string | number>) {
        try {

            const analyticsClientSideData = this.getAnalyticsClientSideData();
            const data = analyticsClientSideData.data;
            this.pushDataLayerItem({
                event: name.toLowerCase(),
                customCountry: data.country,
                customProductAreaId: data.productAreaId,
                customUserId: this.getWoUserId(analyticsClientSideData.config.userInfoCookieKey),
                ...params
            });
        } catch (err) {
            this.Rollbar.error(`WhistleOut.pushGtmEventV2: name=${name}`, err);
        }
    }

    pushGtmPageView(config: AnalyticsClientSideData['config'], data: AnalyticsClientSideData['data']) {
        try {
            const dimensionNames = ['Author', 'LastUpdated', 'PageType'];
            const dataLayerValue = <WoDataLayerItem>{
                event: 'customPageView',
                customUrl: `${location.pathname}${location.search}`
            };

            wo$.each(data.dimensions, function (index, value) {
                dataLayerValue[dimensionNames[index]] = value;
            });

            dataLayerValue['customCountry'] = data.country;
            dataLayerValue['customUserId'] = this.getWoUserId(config.userInfoCookieKey);

            this.pushDataLayerItem(dataLayerValue);
        } catch (err) {
            this.Rollbar.error('WhistleOut.pushGtmPageView', err);
        }
    }

    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    getAds(productArea: string) {
        const placements = wo$('[data-adplacement]');
        if (placements.length > 0) {
            const data = {
                productArea: productArea,
                url: location.pathname + location.search,
                placements: wo$.makeArray(
                    placements.map((i, el) => {
                        const data = wo$(el).data();
                        return {
                            name: data.adplacement,
                            tab: data.tab
                        };
                    })
                )
            };
            wo$.ajax({
                url: '/Ajax/Shared/Ad/Get',
                data: data,
                dataType: 'json',
                type: 'POST',
                success: result => {
                    for (let x = 0; x < result.length; x++) {
                        this.processAd(placements, result[x]);
                    }
                    this.applyPopover();
                    this.stopPropagation();
                    this.bindSubscribe();
                    this.notifications.fire('AdsLoaded', { ads: result });
                }
            });
        }
    }

    processAd(placements: JQuery<HTMLElement>, ad: Ad) {
        const filter =
            ad.Tab == null
                ? '[data-adplacement = "' + ad.Placement + '"]'
                : '[data-adplacement = "' + ad.Placement + '"][data-tab = "' + ad.Tab + '"]';
        const matchingPlacements = placements.filter(filter);
        if (matchingPlacements.length > 0) {
            let html = ad.Content;
            if (ad.TrackingHtml != null) {
                html += '<div style="display: none">' + ad.TrackingHtml + '</div>';
            }
            if (html === '' || html == null) {
                if (ad.Placement !== 'TopNav') {
                    // TODO: Fix invalid call, old API?
                    matchingPlacements.slideUp(250, this.setAd(matchingPlacements, null) as unknown as string);
                }
            } else if (ad.Placement === 'StickyBottomStrip') {
                if (
                    typeof Cookies.get('ShowStickyBottomStrip') === 'undefined' ||
                    Cookies.get('ShowStickyBottomStrip') === null
                ) {
                    wo$('#suggested-articles').remove();
                    this.setAd(matchingPlacements, ad);

                    matchingPlacements.each((i, el) => {
                        this.setStickyBottomStripAd(wo$(el), ad);
                    });
                }
            } else {
                this.setAd(matchingPlacements, ad);
                matchingPlacements.slideDown(250);
            }
        }
    }

    setAd(placements: JQuery<HTMLElement>, ad: Ad) {
        let html = '';
        if (ad != null) {
            html = ad.Content;
            if (ad.TrackingHtml != null) {
                html += '<div style="display: none">' + ad.TrackingHtml + '</div>';
            }
        }

        const onPlacement = (el: HTMLElement) => {
            const placement = wo$(el);
            if (placement.children().length === 0) {
                placement.html(html);
            } else {
                placement.children().first().html(html);
            }
            if (ad != null && ad.Id != null) {
                const label = placement.data('adplacement') + ': ' + ad.Name + ' (' + ad.Id + ')';

                const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                    if (entries.length > 0) {
                        const entry = entries[0];
                        if (entry.isIntersecting) {
                            this.trackEvent('Ad', 'Show', label, { nonInteraction: true });
                            observer.disconnect();
                        }
                    }
                }, {
                    root: null,
                    threshold: 0.1
                });
                observer.observe(placement[0]);

                placement.off('click').click(() => {
                    this.trackEvent('Ad', 'Click', label);
                });

                EmailMySearch.adInit(
                    placement.find('#ad-email-my-search'),
                    placement.find('#ad-email-my-search').find('#my-search-ad-submit')
                );

            }
        };

        placements.each((i, el: HTMLElement) => {
            onPlacement(el);
        });
    }

    setStickyBottomStripAd(placement: JQuery<HTMLElement>, ad: Ad) {
        placement
            .affix({
                offset: { top: 400 }
            })
            .show()
            .find('[data-adclose]')
            .off('click')
            .on('click', (event: Event) => {
                const expires = new Date();
                expires.setTime(expires.getTime() + 60 * 60 * 1000);
                Cookies.set('ShowStickyBottomStrip', '0', { path: '/', expires: expires });
                placement.remove();
                const label = placement.data('adplacement') + ': ' + ad.Name + ' (' + ad.Id + ')';
                this.trackEvent('Ad', 'Close', label);
                event.stopPropagation();
            });
    }

    hideAllPopovers(e: JQuery.TriggeredEvent<HTMLElement>) {
        if (wo$('.popover.in').length === 0) {
            return;
        }

        wo$('[data-toggle="popover"]').each((i, el) => {
            const jObj = wo$(el);
            if (
                !jObj.is(e.target) &&
                jObj.has(e.target).length === 0 &&
                wo$('.popover').has(e.target).length === 0 &&
                wo$('.popover').hasClass('in')
            ) {
                jObj.popover('hide');
            }
        });
    }

    applyPopover = (container?: JQuery<HTMLElement>) => {
        if (typeof container !== 'undefined') {
            container.find('[data-toggle="popover"]').popover({ html: true });
        }

        wo$('[data-toggle="popover"]').popover({ html: true });
        wo$('body').off('click', this.hideAllPopovers).on('click', this.hideAllPopovers);
        wo$('body')
            .off('hidden.bs.popover')
            .on('hidden.bs.popover', e => {
                if (wo$(e.target).data('bs.popover')) {
                    wo$(e.target).data('bs.popover').inState.click = false;
                }
            });
    };

    triggerPopoverNotification(element: HTMLElement, content: string, position: Bootstrap.Placement) {
        // eslint-disable-next-line eqeqeq
        if (typeof position == 'undefined') {
            position = 'right';
        }
        const popoverElement = wo$(element);
        popoverElement.popover({
            trigger: 'manual',
            placement: position,
            html: true,
            content: content
        });
        popoverElement.popover('show');
        setTimeout(function () {
            popoverElement.popover('hide');
        }, 1200);
    }

    checkQueryParamExists(queryParam: string) {
        const url = location.href;
        if (url.indexOf('?' + queryParam + '=') !== -1) return true;
        else if (url.indexOf('&' + queryParam + '=') !== -1) return true;
        return false;
    }

    storeSelectorModal: { show: (selectedHandler: selectStoreHandler) => void } = {
        show: function (selectedHandler) {
            const modal = wo$('#store-selector-modal');
            if (!modal.length) return;

            modal.keyup(e => {
                if (e.keyCode === 13) {
                    this.submitStoreSelectorModal(selectedHandler);
                }
            });

            modal.find('#continue-button').click(() => {
                this.submitStoreSelectorModal(selectedHandler);
            });
            const $staff = modal.find('#staff');
            if ($staff.length > 0) {
                $staff.typeahead({
                    minLength: 2,
                    items: 'all',
                    addItem: {
                        id: 0,
                        code: 'Other',
                        name: 'Other'
                    },
                    source: function (query, process) {
                        return wo$.ajax({
                            url: '/Store/FindStaff',
                            data: { search: query },
                            dataType: 'json',
                            type: 'POST',
                            success: function (json) {
                                return process(json);
                            }
                        });
                    },
                    afterSelect: function (item: TypeaheadItem) {
                        $staff.data('id', item.id);
                        $staff.data('code', item.code);
                        $staff.data('name', item.name);
                    },
                    matcher: function () {
                        return true;
                    },
                    displayText: function (item: TypeaheadItem) {
                        return item.code ? item.name + ' (' + item.code + ')' : item.name;
                    }
                });
            }

            const siteConfiguration = this.getSiteConfiguration();
            const currentStoreId = Cookies.get(siteConfiguration.selectedStoreCookieKey);
            if (currentStoreId) {
                modal.find('#store-select').val(currentStoreId);
            }

            modal.modal('show').on('shown.bs.modal', function () {
                modal.find('#staff').focus();
            });
        }
    };

    submitStoreSelectorModal(selectedHandler: selectStoreHandler) {
        const modal = wo$('#store-selector-modal');
        if (!modal.length) return;

        const selectedStoreId = modal.find('#store-select').val() as string;

        const staff = modal.find('#staff');
        let selectedStaff = null;
        if (staff.length > 0) {
            selectedStaff = {
                id: staff.data('id'),
                code: staff.data('code'),
                name: staff.data('name')
            };
        }

        if (
            !selectedStoreId ||
            selectedStoreId.length <= 0 ||
            // TODO: Fix the violation and enable the rule
            // eslint-disable-next-line eqeqeq
            (staff.length > 0 && (!selectedStaff || selectedStaff.id == undefined))
        ) {
            modal.find('#store-error').show();
            return;
        }

        selectedHandler(selectedStoreId, selectedStaff);
        modal.find('#store-error').hide();
        modal.modal('hide');
    }

    showStoreSelector() {
        const siteConfiguration = this.getSiteConfiguration();
        if (
            (siteConfiguration &&
                siteConfiguration.forceStoreSelection === true &&
                (Cookies.get(siteConfiguration.selectedStoreCookieKey) == null ||
                    Cookies.get(siteConfiguration.selectedStoreCookieKey) === '')) ||
            (siteConfiguration &&
                siteConfiguration.forceStoreStaffSelection === true &&
                (Cookies.get(siteConfiguration.selectedStaffCookieKey) == null ||
                    Cookies.get(siteConfiguration.selectedStaffCookieKey) === ''))
        ) {
            this.storeSelectorModal.show(this.selectStore);
        }
    }

    resetStore() {
        const siteConfiguration = this.getSiteConfiguration();
        Cookies.remove(siteConfiguration.selectedStoreCookieKey, { path: '/' });
        Cookies.remove(siteConfiguration.selectedStaffCookieKey, { path: '/' });

        // TODO: Browser ignores the parameter in `location.reload(true)`. If needed it should be done differently
        // see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
        location.reload();
    }

    resetStaff(reload?: boolean) {
        const siteConfiguration = this.getSiteConfiguration();
        Cookies.remove(siteConfiguration.selectedStaffCookieKey, { path: '/' });
        if (reload) {
            // TODO: Browser ignores the parameter in `location.reload(true)`. If needed it should be done differently
            // see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
            location.reload();
        }
    }

    selectStore(selectedStoreId: string, selectedStaff: unknown) {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        Cookies.set(this.getSiteConfiguration().selectedStoreCookieKey, selectedStoreId, {
            path: '/',
            expires: expires
        });

        if (selectedStaff) {
            Cookies.set(this.getSiteConfiguration().selectedStaffCookieKey, JSON.stringify(selectedStaff), {
                path: '/',
                expires: expires
            });
        }

        // TODO: Browser ignores the parameter in `location.reload(true)`. If needed it should be done differently
        // see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
        location.reload();
    }

    getStaff(): Staff {
        const value = Cookies.get(this.getSiteConfiguration().selectedStaffCookieKey);
        return JSON.parse(value);
    }

    getStaffId() {
        const staff = this.getStaff();
        return staff ? staff.id : '';
    }

    getStaffCode() {
        const staff = this.getStaff();
        return staff ? staff.code : '';
    }

    getStaffName() {
        const staff = this.getStaff();
        return staff ? staff.name : '';
    }

    getStoreName() {
        return this.getSiteConfiguration().storeName;
    }

    getStoreEmail() {
        return this.getSiteConfiguration().storeEmail;
    }

    async bindStateSwitcher() {
        try {
            await deferred.initBootstrapSelect();
            const $container = wo$('#state-switcher-container');
            if ($container.length === 0) return;

            const $province = $container.find('#province');
            if ($province && $province.length > 0) {
                this.applySelectPicker($province);
                this.applySelectPickersStyle();

                if (typeof Cookies.get('state') !== 'undefined' && Cookies.get('state') !== null) {
                    $province.selectpicker('val', Cookies.get('state'));
                }

                $province.on('change', (e: Event) => {
                    const el = e.currentTarget as HTMLSelectElement;
                    let href: string;
                    if (el.value === null || el.value === '') {
                        Cookies.remove('state', { path: '/' });
                        href = this.updateQueryStringParameter(location.href, 'state', null);
                    } else {
                        href = this.updateQueryStringParameter(location.href, 'state', el.value);
                    }

                    // TODO: Browser ignores the parameter in `location.reload(true)`. If needed it should be done differently
                    // see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
                    if (href === location.href) window.location.reload();
                    else location.href = href;
                });
            }
        } catch (e) {
            console.log('Error in WhistleOut.bindStateSwitcher:', e);
        }
    }

    bindModalLinks() {
        try {
            wo$('.js-modal-link').magnificPopup({
                type: 'iframe',
                removeDelay: 160,
                preloader: false,
                fixedContentPos: false
            });
        } catch (e) {
            console.log('Error in WhistleOut.bindModalLinks:', e);
        }
    }

    bindTextAdLinks() {
        wo$('#text-ads a').click(() => {
            const label = wo$(this).html();
            this.trackEvent('TextAd', 'Click', label);
        });
    }

    bindExpressResults() {
        wo$('#express-results-stop, #express-results-stop2').click(() => {
            Cookies.remove('expressmode', { path: '/' });
            const href = this.updateQueryStringParameter(location.href, 'express', null);

            // TODO: Browser ignores the parameter in `location.reload(true)`. If needed it should be done differently
            // see: https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
            if (href === location.href) window.location.reload();
            else location.href = href;
        });
    }

    bindRetailSplash() {
        this.startRetailSplashTimer();
        wo$(document).on('click keydown keyup mousemove scroll', this.startRetailSplashTimer);
    }

    startRetailSplashTimer() {
        if (this.retailSplashTimer) {
            window.clearTimeout(this.retailSplashTimer);
        }
        this.retailSplashTimer = window.setTimeout(() => {
            if (wo$('#store-selector-modal').hasClass('in')) {
                this.startRetailSplashTimer();
                return;
            }

            wo$('#chatlio-widget').hide();
            wo$('#modal-retail-splash')
                .modal('show')
                .on('hide.bs.modal', () => {
                    this.startRetailSplashTimer();
                    wo$('#chatlio-widget').show();
                });
        }, 300000);
    }

    focusAndSelect(element: HTMLInputElement, copyToClipboard?: boolean) {
        const len = (wo$(element).val() as string).length;
        (wo$(element)[0] as HTMLInputElement).setSelectionRange(0, len, 'backward');
        wo$(element).focus();
        if (copyToClipboard === true) {
            document.execCommand('Copy');
        }
    }

    applySelectPicker(element: JQuery<HTMLElement>, config?: BootstrapSelectOptions) {
        const callback = () => {
            element
                .selectpicker('destroy')
                .selectpicker(config || {})
                .selectpicker('refresh');
        };

        if (wo$.fn.selectpicker) {
            callback();
            return;
        }

        deferred.initBootstrapSelect().then(callback);
    }

    applySelectPickersStyle(parent?: JQuery<HTMLElement>) {
        const callback = () => {
            if (!parent) {
                parent = wo$('document,html');
            }
            parent.find('.filter-option').addClass('needsclick');
        };

        if (wo$.fn.selectpicker) {
            callback();
            return;
        }

        deferred.initBootstrapSelect().then(callback);
    }

    stopPropagation() {
        wo$('[data-stop-propagation]').click(function (event) {
            event.stopPropagation();
        });
        wo$('.yamm .dropdown-menu').click(function (event) {
            const target = wo$(event.target);
            if (!target.is('a')) event.stopPropagation();
        });
    }

    bindCta(parent?: JQuery<HTMLElement | Document>) {
        if (!parent) parent = wo$(document);

        parent.find('a[data-cta]').on('click', (e: Event) => {
            const data = wo$(e.currentTarget).data();
            const action = data.cta;
            const label = data.supplier;
            this.trackEvent('Transaction', action, label);
        });
    }

    bindClickUrl() {
        wo$('[data-click-url]').on('click', (e: Event) => {
            const url = wo$(e.currentTarget).data('click-url');
            if (url) {
                location.href = url;
            }
        });
    }

    bindTrackClick() {
        wo$('[data-track-click]').on('click', (e: Event) => {
            const url = wo$(e.currentTarget).data('track-click');
            if (url) {
                const html = '<iframe src="' + url + '" style="display: none"></iframe>';
                wo$('body').append(html);
            }
        });
    }

    applyPromoAds(element: JQuery<HTMLElement>) {
        element.find('[data-promo-click]').on('click', (e: Event) => {
            e.stopPropagation();
            const parent = wo$(e.currentTarget).closest('[data-promo-url]');
            const supplier = parent.data('supplier');
            const url = parent.data('promo-url');
            this.trackEvent('DealStrip', 'Click', supplier);
            window.open(url, '_blank');
        });
    }

    checkSiteQueryString() {
        const state = this.getQueryStringParameter('state');
        if (state !== null) {
            if (state === '') Cookies.remove('state', { path: '/' });
            else Cookies.set('state', state, { path: '/', expires: 31 });
        }

        const express = this.getQueryStringParameter('express');
        if (express !== null) {
            Cookies.set('expressmode', 'true', { path: '/', expires: 60 });
        }
    }

    initHistoryTracker(obj: unknown) {
        obj.HistoryWrapper = {
            pushState: (data, title, url) => {
                history.pushState(data, title, url);
            },

            pushQuery: queryString => {
                const newUrl = this.replaceQueryString(
                    `${window.location.origin}${window.location.pathname}`,
                    queryString
                );
                history.pushState(null, document.title, newUrl);
            }
        };

        window.onpopstate = () => {
            location.reload();
        };
    }

    scrollTo(top: number | string | HTMLElement): void;
    scrollTo(top: number, scrollDuration?: number): void;
    scrollTo(target: string, scrollDuration?: number, scrollOffset?: number): void;
    scrollTo(target: number | string | HTMLElement, scrollDuration?: number, scrollOffset?: number): void {
        if (typeof target === 'number') {
            window.scrollTo({
                top: target as unknown as number,
                behavior: 'smooth'
            });
            return;
        }

        if (typeof scrollDuration === 'undefined') {
            scrollDuration = scrollDefaultDuration;
        }
        if (typeof scrollOffset === 'undefined') {
            scrollOffset = scrollDefaultOffset;
        }

        const el =
            typeof target === 'string'
                ? target?.startsWith('#')
                    ? document.getElementById(target.slice(1))
                    : document.querySelector<HTMLElement>(target)
                : target;

        if (!el) {
            return;
        }

        const y = el.getBoundingClientRect().top + window.scrollY;
        this.scrollTo(y + scrollOffset);
    }

    bindScrollTo() {
        wo$('a[data-scrollto]').on('click', (e: Event) => {
            e.stopPropagation();
            e.preventDefault();
            const el = e.currentTarget as HTMLElement;
            const data = el.dataset;
            const scrollto = data.scrollto;
            const scrollDuration = Number.parseInt(data.scrollduration) || scrollDefaultDuration;
            const scrollOffset = Number.parseInt(data.scrolloffset) || scrollDefaultOffset;
            this.scrollTo(scrollto, scrollDuration, scrollOffset);
        });
    }

    bindShow() {
        wo$('a[data-show]').on('click', (e: Event) => {
            const target = wo$(e.currentTarget).data('show');
            wo$(target).show();
        });
    }

    bindFocusAndSelect() {
        wo$('input[data-focusandselect]').on('click', (e: Event) => {
            const el = e.currentTarget as HTMLInputElement;
            this.focusAndSelect(el);
        });
    }

    bindTrackEvent() {
        wo$('body').on('click', '[data-trackevent]', (e: Event) => {
            const el = e.currentTarget as HTMLElement;
            const data = wo$(el).data();
            const category = data.category;
            const action = data.action;
            const label = data.label;
            this.trackEvent(category, action, label);
        });
    }

    async bindSlick() {
        await deferred.initSlick();

        const container = wo$('.slick-container .responsive');
        container.show();

        container.slick({
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 14,
            slidesToScroll: 14,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 10,
                        slidesToScroll: 10,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: false
                    }
                }
            ]
        });
    }


    getRemarketingData(): RemarketingData {
        const remarketing = wo$('#remarketing');
        return remarketing.length === 0 ? null : this.readLookupData(remarketing);
    }

    remarketing(data: RemarketingData) {
        if (!this.isTargetingCookieEnabled()) {
            return;
        }

        const trackingCode = this.getSiteConfiguration().remarketingTrackingCode;
        if (data && typeof gtag !== 'undefined' && trackingCode) {
            gtag('event', 'conversion', {
                allow_custom_scripts: true,
                send_to: trackingCode,
                u1: data.supplier ? data.supplier : '',
                u2: data.data ? data.data : '',
                u3: data.planType ? data.planType : '',
                u4: data.phoneBrand ? data.phoneBrand : '',
                u5: data.phone ? data.phone : '',
                u6: data.tags ? data.tags : '',
                u7: data.connectionType ? data.connectionType : '',
                u8: data.bundles ? data.bundles : '',
                u9: data.numberOfLines ? data.numberOfLines : '',
                u10: data.postcode ? data.postcode : '',
                u11: data.tabletBrand ? data.tabletBrand : '',
                u12: data.tablet ? data.tablet : '',
                u13: data.speed ? data.speed : ''
            });
        }
    }

    bindGenericCallback() {
        wo$('[data-callback]').each(() => {
            const form = wo$(this);
            form.find('[data-submit]')
                .off('click')
                .on('click', () => {
                    form.find('[data-success], [data-error]').hide();
                    form.find('.has-error').removeClass('has-error');
                    const $name = form.find('[data-name]');
                    const $phone = form.find('[data-phone]');
                    const $state = form.find('[data-state]');
                    const data = {
                        name: ($name.val() as string).trim(),
                        phone: ($phone.val() as string).trim(),
                        time: form.find('[data-time]').val(),
                        state: $state.val()
                    };
                    let error = false;
                    if (data.name === '') {
                        $name.parents('.form-group').addClass('has-error');
                        error = true;
                    }
                    const config = this.getSiteConfiguration();
                    const regex = new RegExp(config.regex.phone);
                    if (data.phone === '' || !regex.test(data.phone)) {
                        $phone.parents('.form-group').addClass('has-error');
                        error = true;
                    }
                    if (data.state === '') {
                        $state.parents('.form-group').addClass('has-error');
                        error = true;
                    }
                    if (!error) {
                        wo$.ajax({
                            url: '/Ajax/Shared/Callback/SubmitGeneric',
                            type: 'POST',
                            data: data,
                            success: function () {
                                form.find('[data-intro]').hide();
                                form.find('[data-success]').show();
                                form.find('[data-name]').val('');
                                form.find('[data-phone]').val('');
                                form.find('[data-state]').val('');
                            },
                            error: function () {
                                form.find('[data-intro]').hide();
                                form.find('[data-error]').show();
                            }
                        });
                    }
                });
        });
    }

    checkAffiliateDomainCookie() {
        const config = this.getSiteConfiguration();
        if (config) {
            if (config.affiliateDomainId === 0) {
                Cookies.remove(config.cookieName, {
                    path: '/',
                    domain: config.cookieDomain
                });
            } else if (config.cookieDuration && config.cookieDuration !== '') {
                const expires = new Date();
                if (config.cookieDuration === -1) {
                    expires.setTime(expires.getTime() + 525600 * 60 * 60 * 1000);
                } else {
                    expires.setTime(expires.getTime() + (config.cookieDuration as number) * 60 * 1000);
                }
                Cookies.set(config.cookieName, String(config.affiliateDomainId), {
                    path: '/',
                    expires: expires,
                    domain: config.cookieDomain
                });
            }
        }
    }

    bindDataTables() {
        function applySorting(element, config) {
            const sort = element.find('th[data-order]');
            const index = sort.index();
            const direction = sort.data('order') || 'asc';
            if (sort.length) {
                config.order = [[index, direction]];
            }
        }
        if (wo$.fn.dataTable) {
            wo$.fn.dataTable.ext.errMode = 'throw';
            wo$.extend(wo$.fn.dataTable.defaults, {
                searching: false,
                paging: false,
                info: false,
                ordering: true
            });

            wo$('.dataTables-sortableResponsive').each((i, el) => {
                const config = {
                    columnDefs: [
                        {
                            orderable: false,
                            targets: -1
                        },
                        {
                            orderSequence: ['desc', 'asc'],
                            targets: '_all'
                        }
                    ],
                    fixedHeader: {
                        headerOffset: 60
                    },
                    responsive: {
                        details: false
                    }
                };

                const jobj = wo$(el);
                applySorting(jobj, config);
                jobj.DataTable(config);
            });

            wo$('.dataTables-sortableResponsiveAllColumns').each((i, el) =>{
                const config = <DataTables.Settings>{
                    columnDefs: [
                        {
                            orderable: true,
                        },
                        {
                            orderSequence: ['desc', 'asc'],
                            targets: '_all'
                        }
                    ],
                    fixedHeader: {
                        headerOffset: 60
                    },
                    responsive: {
                        details: false
                    }
                };

                const jobj = wo$(el);
                applySorting(jobj, config);
                jobj.DataTable(config);
            });

            wo$('.dataTables-Sortable').each((i, el) => {
                const config = <DataTables.Settings>{
                    columnDefs: [
                        {
                            orderable: true
                        },
                        {
                            orderSequence: ['desc', 'asc'],
                            targets: '_all'
                        }
                    ],
                    fixedHeader: false,
                    responsive: false
                };

                const jobj = wo$(el);
                applySorting(jobj, config);
                jobj.DataTable(config);
            });

            // TODO: Object doesn't match the type definition, fix
            wo$('.dataTables-notSortable').DataTable({
                fixedHeader: {
                    headerOffset: 60
                },
                responsive: {
                    details: true
                },
                ordering: false
            } as unknown as DataTables.Settings);

            // TODO: Object doesn't match the type definition, fix
            wo$('.dataTables-fixedHeight').DataTable({
                fixedHeader: false,
                responsive: false,
                ordering: false,
                scrollY: '500px',
                scrollX: true,
                scrollCollapse: true
            } as unknown as DataTables.Settings);
        }
    }

    bindCitiesAutoComplete(page: JQuery<Document>) {
        const container = page.find('#cities');
        if (!container.length) return;

        const input = container.find('#search-city-input');
        input.val('');
        input.prop('disabled', false);

        const area = input.data('area');

        const icon = container.find('#city-search-icon');
        icon.removeClass();
        icon.addClass('fa fa-search');

        input.typeahead(<Bootstrap3Typeahead.Options>{
            selectOnBlur: false,
            minLength: 3,
            source: function (query, process) {
                return wo$.ajax({
                    url: '/Ajax/Shared/Geo/Cities?query=' + query + '&area=' + area,
                    dataType: 'json',
                    success: function (json) {
                        return process(json);
                    }
                });
            },
            afterSelect: function (item: { url: string; displayText: string }) {
                if (item.url) {
                    container.find('#search-city-input').prop('disabled', true);
                    const icon = container.find('#city-search-icon');
                    icon.removeClass();
                    icon.addClass('fa fa-spinner fa-fw fa-spin');
                    location.href = item.url;
                }
            },
            matcher: function () {
                return true;
            },
            displayText: function (item) {
                return (item as unknown as { displayText: string }).displayText;
            }
        });

        container.find('#search-city-input').on('blur.bootstrap3Typeahead', function () {
            if (!container.find('#search-city-input').prop('disabled')) {
                container.find('#search-city-input').val('');
            }
        });
    }

    setPrivateDataCookieValue(disabled: boolean) {
        if (typeof window === 'undefined' || !window || !wo$) {
            return;
        }

        Cookies.set('hidePrivateData', String(disabled), { path: '/', expires: 365 });
    }

    getPrivateDataCookieValue() {
        if (typeof window === 'undefined' || !window || !wo$) {
            return false;
        }

        const result = Cookies.get('hidePrivateData');
        if (typeof result === 'undefined' || result == null) {
            return false;
        }
        return result === 'true' || /true/i.test(result);
    }

    privateDataModalId = 'modal-privatedata';

    bindPrivateDataModal(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        const hideData = this.getPrivateDataCookieValue();
        const modal = container.find(`#${this.privateDataModalId}`);

        modal.off('show.bs.modal').on('show.bs.modal', () => {
            const checkBox = container.find('#private-data-checkbox');
            checkBox.prop('checked', hideData);
            const applyButton = container.find('#private-data-apply-button');
            applyButton.off().click(() => {
                const checked = checkBox.prop('checked');
                if (checked !== hideData) {
                    this.setPrivateDataCookieValue(checked);

                    location.reload();
                }
            });
        });
    }

    bindPhoneSpecsModal(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        container
            .find('a[data-phone-specs-button]')
            .off('click')
            .on('click', (e: Event) => {
                const el = e.currentTarget;
                const phoneShortUrl = wo$(el).data('phone-specs-button');
                if (!phoneShortUrl || phoneShortUrl.length < 1) return;

                let phoneSpecsContainer = wo$('#phone-specs-container');
                if (
                    phoneSpecsContainer &&
                    phoneSpecsContainer.length > 0 &&
                    phoneSpecsContainer.data('phone-short-url') === phoneShortUrl
                ) {
                    phoneSpecsContainer.find('[data-phone-specs-modal]').modal('show');
                } else {
                    const data = {
                        phoneShortUrl: phoneShortUrl
                    };
                    const siteConfiguration = this.getSiteConfiguration();
                    wo$.ajax({
                        url: siteConfiguration.phoneSpecsModalUrl,
                        data: data,
                        type: 'GET',
                        success: function (result) {
                            if (result !== '') {
                                if (!phoneSpecsContainer || phoneSpecsContainer.length < 1) {
                                    wo$('body').append('<div id="phone-specs-container"></div>');
                                    phoneSpecsContainer = wo$('#phone-specs-container');
                                }
                                phoneSpecsContainer.data('phone-short-url', phoneShortUrl);
                                phoneSpecsContainer.html(result);
                                phoneSpecsContainer.find('[data-phone-specs-modal]').modal('show');
                            }
                        }
                    });
                }
            });
    }

    bindTabletSpecsModal(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        container
            .find('a[data-tablet-specs-button]')
            .off('click')
            .on('click', (e: Event) => {
                const tabletShortUrl = wo$(e.currentTarget).data('tablet-specs-button');
                if (!tabletShortUrl || tabletShortUrl.length < 1) return;

                this.showTabletSpecs(tabletShortUrl);
            });
    }

    showTabletSpecs(tabletShortUrl: string) {
        let tabletSpecsContainer = wo$('#tablet-specs-container');
        if (
            tabletSpecsContainer &&
            tabletSpecsContainer.length > 0 &&
            tabletSpecsContainer.data('tablet-short-url') === tabletShortUrl
        ) {
            tabletSpecsContainer.find('[data-tablet-specs-modal]').modal('show');
        } else {
            const data = {
                tabletShortUrl: tabletShortUrl
            };
            const siteConfiguration = this.getSiteConfiguration();
            wo$.ajax({
                url: siteConfiguration.tabletSpecsModalUrl,
                data: data,
                type: 'GET',
                success: function (result) {
                    if (result !== '') {
                        if (!tabletSpecsContainer || tabletSpecsContainer.length < 1) {
                            wo$('body').append('<div id="tablet-specs-container"></div>');
                            tabletSpecsContainer = wo$('#tablet-specs-container');
                        }
                        tabletSpecsContainer.data('tablet-short-url', tabletShortUrl);
                        tabletSpecsContainer.html(result);
                        tabletSpecsContainer.find('[data-tablet-specs-modal]').modal('show');
                    }
                }
            });
        }
    }

    bindBYODModal(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        container
            .find('[data-byod-modal-button]')
            .off('click')
            .on('click', () => {
                let byoModalContainer = wo$('#byo-modal-container');
                if (byoModalContainer && byoModalContainer.length > 0) {
                    this.showBYODModal();
                } else {
                    const siteConfiguration = this.getSiteConfiguration();
                    wo$.ajax({
                        url: siteConfiguration.byoPhonePickerModalUrl,
                        type: 'GET',
                        success: function (result) {
                            if (result !== '') {
                                if (!byoModalContainer || byoModalContainer.length < 1) {
                                    wo$('body').append('<div id="byo-modal-container"></div>');
                                    byoModalContainer = wo$('#byo-modal-container');
                                }
                                byoModalContainer.html(result);
                                this.showBYODModal();
                            }
                        }
                    });
                }
            });
    }

    showBYODModal() {
        if (this.byoPhonePickerController) {
            this.byoPhonePickerController.show(function (
                selectedModel: { searchUrl: string },
                genericSearchUrl: string
            ) {
                if (selectedModel) {
                    location.href = selectedModel.searchUrl;
                } else if (genericSearchUrl) {
                    location.href = genericSearchUrl;
                }
            });
        }
    }

    bindPhoneGallery(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        container
            .find('a[data-phone-gallery-button]')
            .off('click')
            .on('click', (e: Event) => {
                const link = wo$(e.currentTarget);
                const phoneShortUrl = link.data('phone-gallery-button');
                if (!phoneShortUrl || phoneShortUrl.length < 1) return;

                const data = {
                    phoneShortUrl: phoneShortUrl
                };
                const siteConfiguration = this.getSiteConfiguration();
                wo$.ajax({
                    url: siteConfiguration.phoneGalleryUrl,
                    data: data,
                    type: 'GET',
                    success: function (result) {
                        if (result !== '') {
                            const items = [];
                            wo$.each(result, function (index, item) {
                                items.push({ src: item });
                            });

                            wo$.magnificPopup.open({
                                items: items,
                                type: 'image',
                                gallery: {
                                    enabled: true
                                }
                            });
                        }
                    }
                });
            });
    }

    showTabletGallery(tabletShortUrl: string) {
        const data = {
            tabletShortUrl: tabletShortUrl
        };
        const siteConfiguration = this.getSiteConfiguration();
        wo$.ajax({
            url: siteConfiguration.tabletGalleryUrl,
            data: data,
            type: 'GET',
            success: function (result) {
                if (result !== '') {
                    const items = [];
                    wo$.each(result, function (index, item) {
                        items.push({ src: item });
                    });

                    wo$.magnificPopup.open({
                        items: items,
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    });
                }
            }
        });
    }

    bindTabletGallery(container?: JQuery<HTMLElement>) {
        if (typeof container === 'undefined') {
            container = wo$('body');
        }

        container
            .find('a[data-tablet-gallery-button]')
            .off('click')
            .on('click', (e: Event) => {
                const link = wo$(e.currentTarget);
                const tabletShortUrl = link.data('tablet-gallery-button');
                if (!tabletShortUrl || tabletShortUrl.length < 1) return;

                this.showTabletGallery(tabletShortUrl);
            });
    }

    copyToClipboard(value: string) {
        const input = wo$('<input>').appendTo('body');
        input.val(value);
        input.select();
        document.execCommand('Copy');
        input.remove();
    }

    bindSubscribe() {
        const config = this.getSiteConfiguration();
        wo$('[data-subscribe]:not([data-bound])').each((i, el) => {
            const target = wo$(el);
            const email = target.find('[data-email]');
            const listId = target.data('listid');
            const eventLabel = target.data('eventlabel');
            target
                .find('[data-submit]')
                .off('click')
                .on('click', () => {
                    target.find('[data-error]').hide();
                    const regex = new RegExp(config.regex.email);
                    if (!email.val() || !regex.test(email.val() as string)) {
                        target.find('[data-info]').hide();
                        target.find('[data-info]').hide();
                        target.find('[data-validate]').show();
                        return;
                    }
                    if (!target.find('[data-consent]').is(':checked')) {
                        target.find('[data-info]').hide();
                        target.find('[data-validate]').show();
                        return;
                    }
                    const data = {
                        email: email.val(),
                        listId: listId
                    };
                    wo$.ajax({
                        url: config.subscribeUrl,
                        data: data,
                        type: 'POST',
                        success: () => {
                            target.find('[data-formrow]').hide();
                            target.find('[data-success]').show();
                            this.trackEvent('Subscribers', 'Subscribed', eventLabel || listId);
                        },
                        error: function () {
                            target.find('[data-error]').show();
                        },
                        complete: function () {
                            target.find('[data-info]').hide();
                            target.find('[data-validate]').hide();
                        }
                    });
                });
            target.attr('data-bound', true as unknown as string); // TODO: Fix invalid parameter type
        });
    }

    bindNewsCarousel() {
        deferred.initSlick().then(() => {
            wo$('.news-carousel')
                .slick({
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 6,
                                slidesToScroll: 6,
                                infinite: true,
                                dots: false
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4,
                                dots: false
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: false
                            }
                        }
                    ]
                })
                .show();
        });
    }

    bindSlickSingle() {
        deferred.initSlick().then(() => {
            wo$('.slick-single')
                .slick({
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1
                })
                .show();
        });
    }

    encodeHtml(str: string) {
        const el = document.createElement('span');
        el.append(document.createTextNode(str));
        return el.innerHTML;
    }

    trueOrNull(val: string | boolean) {
        return val === true || val === 'true' ? true : null;
    }

    falseOrNull(val: string | boolean) {
        return val === false || val === 'false' ? false : null;
    }

    boolOrNull(val: string | boolean) {
        return this.trueOrNull(val) === true ? true : this.falseOrNull(val) === false ? false : null;
    }

    bindCriteriaFilters<
        TCriteria extends {
            common: SearchCriteriaCommon;
            results: SearchCriteriaCommon['results'];
        }
    >(
        elements: JQuery<HTMLElement>,
        callback: (element: JQuery<HTMLElement>, criteria: TCriteria) => void,
        criteria: TCriteria,
        mappingOverrides: { [key: string]: (e: TCriteria, val: string, key?: string) => unknown }
    ) {
        if (!elements || elements.length === 0) {
            return;
        }

        const getCommon = function (e: TCriteria) {
            if (!e.common) {
                e.common = <TCriteria['common']>{};
            }
            return e.common;
        };

        const getResults = function (e: TCriteria) {
            if (!e.results) {
                e.results = <TCriteria['results']>{};
            }
            return e.results;
        };

        const trueOrNull = this.trueOrNull;
        const falseOrNull = this.falseOrNull;

        // Based on Areas\Shared\React\js\utils\CommonQueryString.js
        const mappings = Object.assign(
            <typeof mappingOverrides>{
                address: function (e, val, key) {
                    getCommon(e)[key] = val;
                },
                cache: function (e, val) {
                    getCommon(e).useCache = falseOrNull(val);
                },
                campaignGroup: function (e, val, key) {
                    getCommon(e)[key] = parseInt(val);
                },
                contract: function (e, val) {
                    getCommon(e).contractTerms = (val || '').split(',') as unknown as number[]; // TODO: Convert explicitly?
                },
                currentsupplier: function (e, val) {
                    getCommon(e).currentSupplierShortUrl = val;
                },
                customer: function (e, val) {
                    getCommon(e).customerType = val;
                },
                dealsonly: function (e, val) {
                    getCommon(e).includeOffersWithCampaignOnly = trueOrNull(val);
                },
                debug: function (e, val, key) {
                    getCommon(e)[key] = trueOrNull(val);
                },
                enforcemaximumresultlimits: function (e, val) {
                    getCommon(e).enforceMaximumResultLimits = falseOrNull(val);
                },
                hidecoverage: function (e, val) {
                    getCommon(e).hideCoverage = trueOrNull(val);
                },
                hideemptytabs: function (e, val) {
                    getCommon(e).hideEmptyTabs = trueOrNull(val);
                },
                hideshare: function (e, val) {
                    getCommon(e).hideShare = trueOrNull(val);
                },
                hidesort: function (e, val) {
                    getCommon(e).hideSort = trueOrNull(val);
                },
                hidewidenresults: function (e, val) {
                    getCommon(e).hideWidenResults = trueOrNull(val);
                },
                includefeatured: function (e, val) {
                    getCommon(e).includeFeaturedResults = falseOrNull(val);
                },
                maxresults: function (e, val) {
                    getResults(e).maximumNumberOfResults = parseInt(val);
                },
                maxresultspersupplier: function (e, val) {
                    getCommon(e).maxResultsPerSupplier = parseInt(val);
                },
                minspend: function (e, val) {
                    getCommon(e).minimumSpend = Number(val);
                },
                monetised: function (e, val) {
                    getCommon(e).monetisedResultsOnly = trueOrNull(val);
                },
                pagesize: function (e, val) {
                    getCommon(e).pageSize = parseInt(val);
                },
                product: function (e, val) {
                    getCommon(e).products = (val || '').split(',') as unknown as number[]; // TODO: Convert explicitly?
                },
                productlabel: function (e, val) {
                    getCommon(e).productLabel = decodeURIComponent(val);
                },
                showall: function (e, val) {
                    getCommon(e).showAllResults = trueOrNull(val);
                },
                showlessresults: function (e, val) {
                    getCommon(e).showLessResults = trueOrNull(val);
                },
                showrowfilters: function (e, val) {
                    getCommon(e).showRowFilters = falseOrNull(val);
                },
                showrowsorts: function (e, val) {
                    getCommon(e).showRowSorts = falseOrNull(val);
                },
                sort: function (e, val) {
                    getCommon(e).resultsSortExpression = val;
                },
                sortascending: function (e, val) {
                    getCommon(e).resultsSortAscending = falseOrNull(val);
                },
                spend: function (e, val) {
                    getCommon(e).maximumSpend = Number(val);
                },
                supplier: function (e, val) {
                    getCommon(e).suppliers = {
                        values: !val || val === '' ? [] : val.split(',')
                    };
                },
                tab: function (e, val, key) {
                    getCommon(e)[key] = val;
                },
                transact: function (e, val) {
                    getCommon(e).hideTransactionLinks = falseOrNull(val);
                },
                upfront: function (e, val) {
                    getCommon(e).maximumUpfront = Number(val);
                }
            },
            mappingOverrides
        );

        // Store the criteria as a string to prevent it from being modified
        const defaultCriteria = JSON.stringify(criteria);

        elements
            .find('[data-criteria]')
            .off('click')
            .on('click', function (e) {
                e.preventDefault();

                let criteria = JSON.parse(defaultCriteria);

                const element = wo$(e.currentTarget);
                const query: string = element.data('criteria') || element.closest('[data-criteria]').data('criteria');
                if (!query) {
                    callback(element, criteria);
                    return;
                }

                if (!criteria) {
                    criteria = {};
                }

                const keys = Object.keys(mappings);
                const args = query.split('&');
                for (let i = 0; i < args.length; i++) {
                    const keyVal = args[i].split('=');

                    if (keyVal[0]) {
                        const key = keyVal[0].toLowerCase();
                        const value = keyVal[1];

                        if (keys.includes(key)) {
                            for (let j = 0; j < keys.length; j++) {
                                if (key === keys[j].toLowerCase()) {
                                    mappings[key](criteria, value, key);
                                    break;
                                }
                            }
                        } else {
                            criteria[key] = value;
                        }
                    }
                }
                callback(element, criteria);
            });
    }

    // Extracted from widgets/js/common
    showTracking() {
        const tracking = wo$('#tracking');
        const config = this.getSiteConfiguration();
        if (config.enableGeoCheck) {
            let country = 0;
            wo$.ajax({
                url: '/cdn-cgi/trace',
                type: 'GET',
                success: function (result) {
                    const matches = result.match('loc=(.{2})');
                    if (matches && matches.length === 2) {
                        switch (matches[1]) {
                            case 'AU':
                                country = 1;
                                break;
                            case 'GB':
                                country = 2;
                                break;
                            case 'US':
                                country = 3;
                                break;
                            case 'NZ':
                                country = 4;
                                break;
                            case 'CA':
                                country = 5;
                                break;
                            case 'MX':
                                country = 8;
                                break;
                            case 'CL':
                                country = 9;
                                break;
                        }
                        if (country === config.country) {
                            tracking.html(tracking.data('html'));
                        }
                    }
                }
            });
        }
    }

    setCoverageCookie(coverage: string) {
        if (typeof window === 'undefined' || !window || !wo$) {
            return;
        }

        if (!coverage) {
            Cookies.set('coverage', null, { path: '/', expires: -1 });
            return;
        }

        Cookies.set('coverage', coverage, { path: '/', expires: 1 });
    }

    initialised: string[] = [];

    /**
     * Initialises module on document ready
     * @param module Module name, typically `import.meta.url`
     * @param init Initialisation function, including support for `async` functions
     */
    initModule(module: string = import.meta.url, init: () => void | Promise<void>) {
        if (this.initialised.includes(module)) {
            return;
        }
        this.initialised.push(module);

        wo$(async () => {
            const result = init();
            if (result instanceof Promise<void>) {
                await result;
            }
        });
    }

    async waitForAddressCookieInit(addressInstance: 'exact' | 'any') {
        console.time(`waitForAddressCookieInit(${addressInstance})`);
        await tryWaitFor(
            () => {
                const state = getState().shared;
                /*
                console.debug({
                    initialiesdWith: state.addressCookie.initialiesdWith?.label,
                    cookie: JSON.parse(Cookies.get('address'))?.label
                });
                */

                return (
                    !document.getElementById('location-header') ||
                    (state.addressCookie.initialiesdWith?.label &&
                        (addressInstance === 'any' ||
                            state.addressCookie.initialiesdWith?.label === JSON.parse(Cookies.get('address'))?.label))
                );
            },
            {
                intervalMs: 100,
                timeoutMs: 500,
                timeoutMessage: `Timeout on waitForAddressCookieInit(${addressInstance})`,
                onTimeout: e => {
                    console.warn(e);
                    this.Rollbar.warning(e);
                }
            }
        );

        console.timeEnd(`waitForAddressCookieInit(${addressInstance})`);
        /*
        const state = getState().shared;
        console.debug({
            initialiesdWith: state.addressCookie.initialiesdWith?.label,
            cookie: JSON.parse(Cookies.get('address'))?.label
        });
        */
    }

    async waitForExtraAnalyticsData() {
        await this.waitForAddressCookieInit('exact');

        // Fallback to any address if the exact check failed
        await this.waitForAddressCookieInit('any');

        console.time('waitForExtraAnalyticsData -> address or coverage cookie present');
        await tryWaitFor(() => !!Cookies.get('address') || !!Cookies.get('coverage'), {
            timeoutMessage: 'Timeout on waiting for address or coverage cookie',
            timeoutMs: 100
        });
        console.timeEnd('waitForExtraAnalyticsData -> address or coverage cookie present');
    }

    async initAnalytics() {
        initDataLayer();

        const analyticsClientSideData = this.getAnalyticsClientSideData();
        if (!analyticsClientSideData) {
            return;
        }

        const { data } = analyticsClientSideData;
        if (!data.trackInitialPageview) {
            return;
        }

        await this.waitForExtraAnalyticsData();

        if (this.scope === 'shared') {
            this.trackPageView(null, null, null, data.referrer);
        } else {
            this.trackPageView();
        }
    }

    /**
     * Common initialisation for both core site and widgets
     **/
    async initBase() {
        this.isInitRequested = true;

        await this.initializeRollbar();
        this.initAnalytics();
        this.applyPopover();

        window.dispatchEvent(new CustomEvent('jQueryReady'));
    }

    async init() {
        if (this.isInitRequested) {
            return;
        }

        await this.initBase();

        queryStringUtils.extendPrototype();

        this.initModule(import.meta.url, async () => {
            this.setYouTubeEnhancedPrivacy();
            this.checkSiteQueryString();
            wo$.blockUI.defaults.overlayCSS = {};

            this.showStoreSelector();
            await this.bindStateSwitcher();
            this.bindModalLinks();
            this.bindTextAdLinks();
            this.bindExpressResults();
            this.bindRetailSplash();
            this.stopPropagation();
            this.bindClickUrl();
            this.bindTrackClick();
            this.bindTrackEvent();
            this.bindShow();
            this.bindScrollTo();
            this.bindFocusAndSelect();
            this.bindGenericCallback();
            this.checkAffiliateDomainCookie();
            this.bindPhoneSpecsModal();
            this.bindTabletSpecsModal();
            this.bindPhoneGallery();
            this.bindTabletGallery();
            this.bindSubscribe();
            this.bindDataTables();
            this.bindPrivateDataModal();
            this.bindBYODModal();
            this.bindNewsCarousel();
            this.bindSlickSingle();

            this.Core.init();
        });
    }
}
